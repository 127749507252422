// ProfilePrivacy.js
import React from 'react';
import { Shield, Info } from 'lucide-react';

const ProfilePrivacy = ({ privateProfile, onToggle }) => {
  return (
    <div className="bg-gray-800/30 backdrop-blur-sm rounded-xl p-8 border border-gray-700/50">
      <div className="flex items-start gap-3 mb-6">
        <Shield className="w-5 h-5 text-blue-400 mt-1" />
        <div>
          <h2 className="text-xl font-semibold">Profile Privacy</h2>
          <p className="text-gray-300 text-sm mt-1">Control access to your explorer profile</p>
        </div>
      </div>
      
      <div className="flex items-center justify-between mb-4">
        <p className="text-sm text-gray-300">Make profile private</p>
        <button
          onClick={onToggle}
          className="relative inline-flex items-center h-6 rounded-full w-11 transition-all duration-300"
          style={{
            backgroundColor: privateProfile ? '#7d1fc2' : 'rgba(55, 65, 81, 0.5)'
          }}
        >
          <span
            className={`
              inline-block w-4 h-4 transform transition-transform duration-300 
              bg-white rounded-full shadow-lg
              ${privateProfile ? 'translate-x-6' : 'translate-x-1'}
            `}
          />
        </button>
      </div>

      <div className="bg-gray-800/50 rounded-lg p-4 flex items-start space-x-3">
        <Info size={20} className="text-blue-400 mt-1 flex-shrink-0" />
        <p className="text-gray-300 text-sm leading-relaxed">
          When your profile is private, other explorers won't be able to see your detailed information and quest history. 
          Your user name will still be visible on the leaderboards.
        </p>
      </div>
    </div>
  );
};

export default ProfilePrivacy;