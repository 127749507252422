import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer, createTransform } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createReduxHistoryContext } from "redux-first-history";
import { createBrowserHistory } from "history";
import { signupReducer } from "./components/signup/SignupReducer";
import { loginReducer } from "./components/login/LoginReducer";
import { profileReducer } from './components/profile/ProfileReducer';
import questsReducer from "./components/quests/QuestsReducer";
import leaderboardReducer from "./components/leaderboards/LeaderboardReducer";

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'], // only auth will be persisted
  transforms: [
    createTransform(
      // transform state on its way to being serialized and persisted
      (inboundState) => {
        // clean sensitive data
        return {
          ...inboundState,
          user: inboundState.user ? {
            ...inboundState.user,
            quest_submissions: undefined // Remove quest submissions data
          } : null
        };
      },
      // transform state being rehydrated
      (outboundState) => {
        return {
          ...outboundState,
          user: outboundState.user || null
        };
      }
    )
  ]
}

const browserHistory = createBrowserHistory();

const {
  createReduxHistory,
  routerMiddleware,
  routerReducer
} = createReduxHistoryContext({ history: browserHistory });

const leaderboardInitialState = {
  data: {
    leaderboard: null,
    records: []
  },
  loading: false,
  error: null
};

// Clear non-auth state when auth changes
const createRootReducer = (state, action) => {
  if (action.type === 'UNSET_CURRENT_USER' || action.type === 'CLEAR_QUESTS_STATE') {
    // Preserve only router state and initialize empty states
    state = { 
      router: state.router,
      quests: {
        quests: { results: [] },
        finishedQuests: [],
        activeTab: "active",
        error: null,
        loading: false
      },
      leaderboards: leaderboardInitialState  // Add this to ensure leaderboard state is reset
    };
  }
  
  return combineReducers({
    router: routerReducer,
    createUser: signupReducer,
    auth: loginReducer,
    quests: questsReducer,
    leaderboards: leaderboardReducer,
    profile: profileReducer,
  })(state, action);
};


const rootReducer = (state, action) => createRootReducer(state, action);

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => 
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
      },
    }).concat(routerMiddleware),
});

export const persistor = persistStore(store);
export const history = createReduxHistory(store);