import axios from "axios";
import { push } from "redux-first-history";
import { toast } from "react-toastify";
import { SET_TOKEN, SET_CURRENT_USER, UNSET_CURRENT_USER } from "./LoginTypes";
import { setAxiosAuthToken, toastOnError } from "../../utils/Utils";
import { FETCH_QUESTS_SUCCESS } from "../quests/QuestsTypes"
import { resetFinishedQuests } from "../quests/QuestsActions"

export const login = (userData, redirectTo, callback) => async (dispatch) => {
  try {
    const response = await axios.post("/api/v1/token/login/", userData);
    const { auth_token } = response.data;
    setAxiosAuthToken(auth_token);
    
    // Reset states before setting new auth
    dispatch(resetFinishedQuests());
    
    dispatch(setToken(auth_token));
    await dispatch(getCurrentUser(redirectTo));
    
    if (callback) {
      callback(true);
    }
    return true;
  } catch (error) {
    console.error("Login error:", error.response ? error.response.data : error.message);
    dispatch(unsetCurrentUser());
    if (error.response) {
      if (error.response.data.non_field_errors) {
        toast.error(error.response.data.non_field_errors[0]);
      } else {
        toast.error(JSON.stringify(error.response.data));
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An unknown error occurred");
    }
    if (callback) {
      callback(false);
    }
    return false;
  }
};

export const getCurrentUser = (redirectTo, callback) => dispatch => {
  axios
    .get("/api/v1/users/me/")
    .then(response => {
      const user = {
        username: response.data.username,
        email: response.data.email
      };
      dispatch(setCurrentUser(user));
      if (redirectTo) {
        dispatch(push(redirectTo));
      }
      if (callback) {
        callback(true);
      }
    })
    .catch(error => {
      dispatch(unsetCurrentUser());
      toastOnError(error);
      if (callback) {
        callback(false);
      }
    });
};

export const setCurrentUser = (user) => dispatch => {
  localStorage.setItem("user", JSON.stringify(user));
  localStorage.setItem("isAuthenticated", "true");
  dispatch({
    type: SET_CURRENT_USER,
    payload: user
  });
};

export const setToken = token => dispatch => {
  setAxiosAuthToken(token);
  localStorage.setItem("token", token);
  dispatch({
    type: SET_TOKEN,
    payload: token
  });
};

export const unsetCurrentUser = () => dispatch => {
  setAxiosAuthToken("");
  localStorage.removeItem("token");
  dispatch({
    type: UNSET_CURRENT_USER
  });
};

export const logout = () => dispatch => {
  axios
    .post("/api/v1/token/logout/")
    .then(response => {
      // Clear all storage
      localStorage.clear(); // This clears everything including persisted state
      
      // Clear all relevant states
      dispatch({ type: "CLEAR_QUESTS_STATE" });
      dispatch({ type: "CLEAR_LEADERBOARD_STATE" });
      dispatch(unsetCurrentUser());
      
      // Reset to initial states
      dispatch({
        type: FETCH_QUESTS_SUCCESS,
        payload: { results: [] }
      });
      
      dispatch(push("/"));
      toast.success("Logout successful.");
    })
    .catch(error => {
      // Clear state even on error
      localStorage.clear();
      dispatch({ type: "CLEAR_QUESTS_STATE" });
      dispatch({ type: "CLEAR_LEADERBOARD_STATE" });
      dispatch(unsetCurrentUser());
      dispatch(push("/"));
      toastOnError(error);
    });
};
