import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { handleGoogleAuth, initiateTwitterAuth } from '../../utils/AuthUtils';
import SocialAuthButton from '../auth/SocialAuthButton';
import { LinkIcon, Info } from 'lucide-react';

const SocialConnections = () => {
  const [socialAccounts, setSocialAccounts] = useState({
    gmailEmail: '',
    twitterHandle: ''
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchSocialConnections = async () => {
    try {
      const response = await axios.get('/api/v1/profile/');
      setSocialAccounts({
        gmailEmail: response.data.gmail_email || '',
        twitterHandle: response.data.twitter_screen_name || ''
      });
    } catch (error) {
      toast.error('Failed to load social connections');
    }
  };

  useEffect(() => {
    fetchSocialConnections();
  }, []);

  const handleGoogleConnect = async (response) => {
    try {
      await handleGoogleAuth(response, dispatch, navigate, true);
    } catch (error) {
      console.error('Google connection error:', error);
    }
  };

  const handleTwitterConnect = async () => {
    try {
      await initiateTwitterAuth(true);
    } catch (error) {
      toast.error('Failed to initiate Twitter connection');
    }
  };

  const handleDisconnect = async (provider) => {
    try {
      await axios.post('/api/v1/disconnect-social-account/', {
        provider: provider
      });
      
      setSocialAccounts(prev => ({
        ...prev,
        [provider === 'google-oauth2' ? 'gmailEmail' : 'twitterHandle']: ''
      }));
      
      toast.success('Social account disconnected successfully');
      await fetchSocialConnections();
    } catch (error) {
      toast.error('Failed to disconnect social account');
    }
  };

  const onFailure = (error) => {
    console.error('Social connection error:', error);
    toast.error('Failed to connect account');
  };

  return (
    <div className="bg-gray-800/30 backdrop-blur-sm rounded-xl p-8 border border-gray-700/50">
      <div className="flex items-start gap-3 mb-6">
        <LinkIcon className="w-5 h-5 text-blue-400 mt-1" />
        <div>
          <h2 className="text-xl font-semibold">Social Connections</h2>
          <p className="text-gray-300 text-sm mt-1">Connect your social accounts for easy login</p>
        </div>
      </div>

      <div className="space-y-6">
        {/* Gmail Connection */}
        <div className="bg-gray-800/50 rounded-lg p-6">
          <div className="flex items-center justify-between gap-4">
            <div className="flex items-center gap-4 flex-grow">
              <img 
                src="/google_logo.png" 
                alt="Google" 
                className="w-6 h-6 object-contain"
              />
              <div className="flex flex-col">
                <h3 className="font-medium text-lg tracking-wide text-gray-100 px-3">
                  Google Account
                </h3>
                {socialAccounts.gmailEmail && (
                  <div className="px-3 py-1 bg-gray-700/50 rounded-md -mt-0.5">
                    <p className="text-gray-300 text-sm m-0">{socialAccounts.gmailEmail}</p>
                  </div>
                )}
              </div>
            </div>
            <div className="flex-shrink-0">
              {socialAccounts.gmailEmail ? (
                <button
                  onClick={() => handleDisconnect('google-oauth2')}
                  className="px-4 py-2 text-sm font-medium text-white bg-red-600/80 rounded-md hover:bg-red-600 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                  Disconnect
                </button>
              ) : (
                <SocialAuthButton
                  provider="google"
                  onSuccess={handleGoogleConnect}
                  onFailure={onFailure}
                  isConnection={true}
                />
              )}
            </div>
          </div>
        </div>

        {/* X (Twitter) Connection */}
        <div className="bg-gray-800/50 rounded-lg p-6">
          <div className="flex items-center justify-between gap-4">
            <div className="flex items-center gap-4 flex-grow">
              <img 
                src="/x_logo.png" 
                alt="X (Twitter)" 
                className="w-6 h-6 object-contain"
              />
              <div className="flex flex-col">
                <h3 className="font-medium text-lg tracking-wide text-gray-100 px-3">
                  X Account
                </h3>
                {socialAccounts.twitterHandle && (
                  <div className="px-3 py-1 bg-gray-700/50 rounded-md -mt-0.5">
                    <p className="text-gray-300 text-sm m-0">@{socialAccounts.twitterHandle}</p>
                  </div>
                )}
              </div>
            </div>
            <div className="flex-shrink-0">
              {socialAccounts.twitterHandle ? (
                <button
                  onClick={() => handleDisconnect('twitter')}
                  className="px-4 py-2 text-sm font-medium text-white bg-red-600/80 rounded-md hover:bg-red-600 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                  Disconnect
                </button>
              ) : (
                <SocialAuthButton
                  provider="twitter"
                  onSuccess={handleTwitterConnect}
                  onFailure={onFailure}
                  isConnection={true}
                />
              )}
            </div>
          </div>
        </div>

        {/* Info Box */}
        <div className="bg-gray-900/30 rounded-lg p-4 flex items-start space-x-3">
          <Info size={20} className="text-blue-400 mt-1 flex-shrink-0" />
          <p className="text-gray-300 text-sm leading-relaxed">
            Connected accounts allow for quick sign-in and help secure your account. 
            Your social media details are kept private and will not be shared with other users.
          </p>
        </div>
      </div>
    </div>
  );
};

export default SocialConnections;