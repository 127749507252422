import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { TwitterLoginButton } from 'react-social-login-buttons';
import { toast } from 'react-toastify';
import React from 'react';
import { handleGoogleAuth, initiateTwitterAuth } from '../../utils/AuthUtils';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const SocialAuthButton = ({ 
  provider, 
  onSuccess = () => {}, 
  onFailure = () => {}, 
  isConnection = false,
  buttonText
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleTwitterLogin = async () => {
    try {
      await initiateTwitterAuth(isConnection);
    } catch (error) {
      toast.error(error.message || 'Failed to initialize Twitter login');
      onFailure(error);
    }
  };

  const handleGoogleSuccess = async (response) => {
    try {
      if (isConnection) {
        await handleGoogleAuth(
          { credential: response.credential },
          dispatch,
          navigate,
          true
        );
        // Don't call onSuccess here - it will be handled in handleGoogleAuth
      } else {
        await handleGoogleAuth(
          { credential: response.credential },
          dispatch,
          navigate,
          false
        );
      }
    } catch (error) {
      console.error('Google auth error:', error);
      onFailure(error);
    }
  };

  if (provider === 'google') {
    return (
      <div className="social-auth-button">
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
          <GoogleLogin
            onSuccess={handleGoogleSuccess}
            onError={(error) => {
              console.error('Google login error:', error);
              onFailure(error);
            }}
            theme="filled_black"
            shape="pill"
            width="280px"
            text={buttonText || (isConnection ? "continue_with" : "signin_with")}
          />
        </GoogleOAuthProvider>
      </div>
    );
  } else if (provider === 'twitter') {
    return (
      <div className="social-auth-button">
        <TwitterLoginButton
          onClick={handleTwitterLogin}
          align="center"
          text={buttonText || (isConnection ? "Connect X Account" : "Sign in with X")}
          buttonTheme="dark_short"
          className="twitter-login-button"
        />
      </div>
    );
  }

  return null;
};

export default SocialAuthButton;