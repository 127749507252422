import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { handleTwitterAuth } from '../../utils/AuthUtils';
import { toast } from 'react-toastify';

const TwitterAuthComplete = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const completeAuth = async () => {
      try {
        const params = new URLSearchParams(location.search);
        const oauth_token = params.get('oauth_token');
        const oauth_verifier = params.get('oauth_verifier');
        const isConnection = sessionStorage.getItem('isConnection') === 'true';
        
        if (!oauth_token || !oauth_verifier) {
          throw new Error('Missing OAuth parameters from Twitter');
        }

        console.log('Starting Twitter auth with params:', {
          oauth_token,
          oauth_verifier,
          isConnection
        });
        
        await handleTwitterAuth(
          { oauth_token, oauth_verifier }, 
          dispatch, 
          navigate, 
          isConnection
        );

      } catch (error) {
        console.error('Auth error:', error);
        const errorMessage = error.response?.data?.error || error.message || 'Authentication failed';
        toast.error(errorMessage);
        
        // Redirect based on the flow
        const redirectPath = sessionStorage.getItem('isConnection') === 'true' 
          ? '/settings' 
          : '/login';
        navigate(redirectPath);
      } finally {
        // Clean up session storage
        sessionStorage.removeItem('isConnection');
        sessionStorage.removeItem('oauth_token_secret');
      }
    };

    completeAuth();
  }, [dispatch, navigate, location]);

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="text-white text-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-white mx-auto mb-4"></div>
        <p>Processing Twitter authentication...</p>
      </div>
    </div>
  );
};

export default TwitterAuthComplete;