import axios from "axios";
import { toastOnError } from "../../utils/Utils";
import { SET_CURRENT_USER } from "../login/LoginTypes";
import { 
  SUBMIT_QUEST,
  DELETE_QUEST_SUBMISSION, 
  UPDATE_QUEST_SUBMISSION, 
  OPEN_QUEST, FETCH_USER_RESPONSE,
  FETCH_QUESTS_SUCCESS,FETCH_QUESTS_FAILURE, 
  FETCH_QUESTS_START,
  SUBMIT_GUEST_QUEST,
  FETCH_SINGLE_QUEST_SUCCESS,
  LIKE_QUEST,
  ADD_QUEST_VIEW,
  SET_ACTIVE_TAB,
  UNLOCK_QUEST,
  RESET_FINISHED_QUESTS
} from "./QuestsTypes";


export const fetchQuests = (isActive) => dispatch => {
  dispatch({ type: FETCH_QUESTS_START });
  
  // Include timestamp to prevent caching
  const timestamp = new Date().getTime();
  const queryParams = isActive !== undefined ? 
    `?active=${isActive}${isActive === false ? '&inactive_only=true' : ''}&_=${timestamp}` : `?_=${timestamp}`;
  
  return axios.get(`/api/v1/quests/${queryParams}`)
    .then(response => {
      dispatch({
        type: FETCH_QUESTS_SUCCESS,
        payload: response.data
      });
    })
    .catch(error => {
      if (error.response?.status === 401) {
        dispatch({
          type: FETCH_QUESTS_SUCCESS,
          payload: { results: [] }
        });
      } else {
        dispatch({
          type: FETCH_QUESTS_FAILURE,
          payload: error.message || 'An error occurred while fetching quests'
        });
      }
    });
};


export const fetchSingleQuest = (identifier) => dispatch => {
  dispatch({ type: FETCH_QUESTS_START });
  
  // Handle both slug and ID cases
  const endpoint = isNaN(identifier) ? 
    `/api/v1/quests/${identifier}/` : 
    `/api/v1/quests/${identifier}/`;
    
  return axios.get(endpoint)
    .then(response => {
      dispatch({
        type: FETCH_SINGLE_QUEST_SUCCESS,
        payload: response.data
      });
      return response.data;
    })
    .catch(error => {
      console.error("Error fetching single quest:", error);
      dispatch({
        type: FETCH_QUESTS_FAILURE,
        payload: error.message || 'An error occurred while fetching the quest'
      });
      throw error;
    });
};


export const openQuest = (questId) => dispatch => {
  console.log(`Opening quest ${questId}`);
  return axios.post(`/api/v1/quests/${questId}/open/`)
    .then(response => {
      console.log('Open quest response:', response.data);
      dispatch({
        type: OPEN_QUEST,
        payload: response.data
      });
      return response.data;
    })
    .catch(error => {
      console.error('Error opening quest:', error.response ? error.response.data : error.message);
      toastOnError(error);
      throw error;
    });
};


export const submitQuest = (questId, userSubmission, submissionPrivacy = 'ANONYMOUS') => (dispatch, getState) => {
  console.log('Submit Quest action initiated with:');
  console.log('questId:', questId);
  console.log('userSubmission:', userSubmission);
  console.log('submissionPrivacy:', submissionPrivacy);

  const token = localStorage.getItem('token');
  
  return axios.post(`/api/v1/quests/${questId}/submit/`, 
    { 
      user_submission: userSubmission,
      feed_privacy: submissionPrivacy
    },
    { headers: { 'Authorization': `Token ${token}` } }
  )
    .then(response => {
      console.log('Submit quest response:', response.data);
      
      // Get current quest state
      const currentQuest = getState().quests.quests.results?.find(q => q.id === questId) ||
                          getState().quests.finishedQuests?.find(q => q.id === questId);

      // Dispatch quest submission update
      dispatch({
        type: SUBMIT_QUEST,
        payload: {
          questId: questId,
          userSubmission: userSubmission,
          feed_privacy: submissionPrivacy,
          unlocked: currentQuest?.user_submission?.unlocked || false,
          unlocked_at: currentQuest?.user_submission?.unlocked_at || null
        }
      });

      // Update user balance if it's included in the response
      if (response.data.specks_balance !== undefined) {
        dispatch({
          type: SET_CURRENT_USER,
          payload: {
            ...getState().auth.user,
            specks_balance: response.data.specks_balance
          }
        });
      }

      return response.data;
    })
    .catch(error => {
      console.error('Error submitting quest:', error);
      toastOnError(error);
      throw error;
    });
};


export const submitGuestQuest = (questId, answer) => dispatch => {
  dispatch({
    type: SUBMIT_GUEST_QUEST,
    payload: { questId, answer }
  });
};


export const deleteQuest = id => dispatch => {
    axios
      .delete(`/api/v1/quests/${id}/`)
      .then(response => {
        dispatch({
          type: DELETE_QUEST_SUBMISSION,
          payload: id
        });
      })
      .catch(error => {
        toastOnError(error);
      });
  };
  
export const updateQuest = (id, quest) => dispatch => {
  axios
    .patch(`/api/v1/quests/${id}/`, quest)
    .then(response => {
      dispatch({
        type: UPDATE_QUEST_SUBMISSION,
        payload: response.data
      });
    })
    .catch(error => {
      toastOnError(error);
    });
};

export const fetchUserResponse = (userId, questId) => dispatch => {
  console.log(`Fetching user response for quest ${questId} and user ${userId}`);
  return axios.get(`/api/v1/quests/${questId}/`)
    .then(response => {
      console.log('Fetch quest response:', response.data);
      const userSubmission = response.data.user_submission || null;
      dispatch({
        type: FETCH_USER_RESPONSE,
        payload: {
          questId: questId,
          userResponse: userSubmission
        }
      });
      return userSubmission;
    })
    .catch(error => {
      console.error('Error fetching user response:', error.response ? error.response.data : error.message);
      toastOnError(error);
      return null;
    });
};

export const likeQuest = (questId) => async (dispatch, getState) => {
  // Get current quest state before update
  const quest = getState().quests.quests.results?.find(q => q.id === questId) || 
                getState().quests.finishedQuests?.find(q => q.id === questId);
                
  if (!quest) return;

  const currentLikeState = quest.is_liked;
  const currentLikesCount = quest.likes_count || 0;

  // Optimistic update
  dispatch({
    type: LIKE_QUEST,
    payload: {
      questId,
      isLiked: !currentLikeState,
      likesCount: currentLikesCount + (currentLikeState ? -1 : 1)
    }
  });

  try {
    // Make the API call
    const response = await axios.post(`/api/v1/quests/${questId}/like/`);
    
    // If the API call was successful, we don't need to do anything
    // because we already updated the state optimistically
    return response.data;
  } catch (error) {
    console.error('Error liking quest:', error);
    
    // Revert optimistic update on error
    dispatch({
      type: LIKE_QUEST,
      payload: {
        questId,
        isLiked: currentLikeState,
        likesCount: currentLikesCount
      }
    });
    
    throw error;
  }
};
  
export const trackQuestView = (questId) => dispatch => {
  return axios.post(`/api/v1/quests/${questId}/view/`)
    .then(response => {
      dispatch({
        type: ADD_QUEST_VIEW,
        payload: { questId }
      });
      return response.data;
    })
    .catch(error => {
      console.error('Error tracking quest view:', error);
      // We don't show errors for view tracking to users
    });
};

export const setActiveTab = (tab) => ({
  type: SET_ACTIVE_TAB,
  payload: tab
});

export const resetFinishedQuests = () => ({
  type: RESET_FINISHED_QUESTS
});

export const unlockQuest = (questId) => async (dispatch, getState) => {
  try {
    const response = await axios.post(`/api/v1/quests/${questId}/unlock/`);
    
    // Update both quest state and user state
    dispatch({
      type: UNLOCK_QUEST,
      payload: {
        questId,
        unlocked: true,
        specks_balance: response.data.specks_balance
      }
    });

    // Update user's specks balance
    dispatch({
      type: SET_CURRENT_USER,
      payload: {
        ...getState().auth.user,
        specks_balance: response.data.specks_balance
      }
    });

    // Fetch fresh quest data to ensure everything is in sync
    dispatch(fetchSingleQuest(questId));

    return response.data;
  } catch (error) {
    console.error('Error unlocking quest:', error);
    toastOnError(error);
    throw error;
  }
};