import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { 
  Users, Heart, Eye, Timer, HelpCircle, 
  CheckCircle2, LockKeyholeOpen 
} from 'lucide-react';
import useCountdown from './../useCountdown';
import { generateQuestSlug } from '../../utils/Utils';
import { unlockQuest } from './QuestsActions';
import Toast from '../ui/Toast';

const UNLOCK_COST = 100;

const UnlockModal = ({ isOpen, onClose, onConfirm, specksBalance, unlocking }) => {
  if (!isOpen) return null;
  
  const hasEnoughSpecks = specksBalance >= UNLOCK_COST;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm z-50 flex items-center justify-center p-4">
      <div className="bg-gray-800 rounded-lg max-w-md w-full p-6">
        <h3 className="text-xl font-semibold mb-4 text-white">
          {hasEnoughSpecks ? "Unlock Quest?" : "Insufficient SPECKS Balance"}
        </h3>
        
        <p className="text-gray-300 mb-6">
          {hasEnoughSpecks ? (
            `Unlock this quest to update / submit your response any time. It costs ${UNLOCK_COST} SPECKS. Proceed?`
          ) : (
            `You need ${UNLOCK_COST} SPECKS to unlock this quest. Complete any active quest to earn SPECKS!`
          )}
        </p>

        <div className="flex justify-end space-x-4">
          {hasEnoughSpecks ? (
            <>
              <button
                onClick={onClose}
                disabled={unlocking}
                className="px-4 py-2 text-gray-300 border border-gray-600 hover:border-gray-500 
                          hover:text-white rounded-lg font-medium transition-all duration-200 
                          disabled:opacity-50 bg-gray-800 hover:bg-gray-700"
              >
                Cancel
              </button>
              <button
                onClick={onConfirm}
                disabled={unlocking}
                className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded 
                          transition-colors duration-200 disabled:opacity-50"
              >
                {unlocking ? 'Unlocking...' : 'Unlock'}
              </button>
            </>
          ) : (
            <button
              onClick={onClose}
              className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded 
                        transition-colors duration-200"
            >
              Okay
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const QuestInList = ({ quest, isAuthenticated, onLikeClick }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showTypeInfo, setShowTypeInfo] = useState(false);
  const { timeLeft, isInitialized } = useCountdown(quest.active_to);
  const [showUnlockModal, setShowUnlockModal] = useState(false);
  const [unlocking, setUnlocking] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState('success');
  const userSpecksBalance = useSelector(state => state.auth.user?.specks_balance) || 0;

  if (!quest) {
    return null;
  }
  
  const handleUnlockClick = () => {
    if (!isAuthenticated) {
      navigate('/login');
      return;
    }
    setShowUnlockModal(true);
  };

  const handleUnlockConfirm = async () => {
    if (unlocking) return;
    
    setUnlocking(true);
    try {
      await dispatch(unlockQuest(quest.id));
      setToastMessage('Quest unlocked successfully!');
      setToastType('success');
      setShowToast(true);
      setShowUnlockModal(false);
      // Navigate to quest page
      navigate(`/quest/${generateQuestSlug(quest.prefix, quest.quest_title)}`);
    } catch (error) {
      setToastMessage(error.response?.data?.error || 'Failed to unlock quest');
      setToastType('error');
      setShowToast(true);
    } finally {
      setUnlocking(false);
    }
  };
  
  const handleLikeClick = (e) => {
    e.preventDefault();
    if (!isAuthenticated) {
      navigate('/login');
      return;
    }
    onLikeClick(quest.id);
  };

  const formatDate = (dateString) => {
    return dateString ? new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    }) : null;
  };

  const formatCountdown = () => {
    if (!timeLeft || timeLeft.isExpired) return null;

    const parts = [];
    if (timeLeft.days > 0) parts.push(`${timeLeft.days}d`);
    if (timeLeft.hours > 0) parts.push(`${timeLeft.hours}h`);
    if (timeLeft.minutes > 0) parts.push(`${timeLeft.minutes}m`);
    parts.push(`${timeLeft.seconds}s`);

    return parts.join(' ');
  };

  const getQuestTypeIcon = (type) => {
    switch (type) {
      case 'question':
        return '❓';
      case 'continue_story':
        return '📝';
      case 'connections':
        return '🔗';
      default:
        return '❓';
    }
  };

  const getQuestTypeName = (type) => {
    switch (type) {
      case 'question':
        return 'Question';
      case 'continue_story':
        return 'Story';
      case 'connections':
        return 'Connections';
      default:
        return 'Unknown Type';
    }
  };

  const getQuestTypeDescription = (type) => {
    const descriptions = {
      "question": "You can answer in a couple of sentences or write an essay. Articulate your thoughts clearly and bring your unique perspective to get high rewards.",
      "continue_story": "Build upon a proposed story beginning; go wherever your mind wanders but try to stay consistent with the established plot.",
      "connections": "Identify and explain intriguing links between seemingly unrelated elements. Present your perspective clearly, supporting your discoveries with logical arguments and relevant facts."
    };
    return descriptions[type] || "";
  };

  return (
    <div className="bg-gray-800 bg-opacity-80 rounded-lg shadow-lg p-3 md:p-6 mb-4 md:mb-6 relative">
      <div className="absolute right-14 -top-2">
        {quest.quest_type && (
          <div 
            className="relative group"
            onMouseEnter={() => setShowTypeInfo(true)}
            onMouseLeave={() => setShowTypeInfo(false)}
          >
            <div className="flex items-center bg-indigo-900 hover:bg-indigo-800 rounded-full px-3 py-1.5 text-sm cursor-help shadow-lg transition-all duration-200">
              <span className="mr-2">{getQuestTypeIcon(quest.quest_type)}</span>
              <span className="text-gray-200">{getQuestTypeName(quest.quest_type)}</span>
              <HelpCircle className="w-4 h-4 ml-1 text-gray-300" />
            </div>
          
          {showTypeInfo && (
            <div className="absolute z-10 w-72 p-4 bg-indigo-950 rounded-lg shadow-xl border border-indigo-700 top-full right-0 mt-2">
              <div className="relative">
                <div className="absolute -top-2 right-4 w-4 h-4 bg-indigo-950 border-l border-t border-indigo-700 transform rotate-45"></div>
              </div>
              <p className="text-gray-200 text-sm pt-2">{getQuestTypeDescription(quest.quest_type)}</p>
            </div>
          )}
          </div>
        )}
      </div>

      {/* Quest status badges */}
      <div className="absolute -top-2 -right-2 flex flex-col gap-2">
        {quest.user_submission?.is_submitted && (
          <div className="bg-green-500 rounded-full p-2 shadow-lg transition-all duration-200 group">
            <CheckCircle2 className="w-6 h-6 text-white" />
            <div className="absolute hidden group-hover:block right-0 mt-2 bg-gray-900 text-gray-200 text-sm py-1 px-3 rounded-md border border-gray-700 whitespace-nowrap">
              Quest completed
            </div>
          </div>
        )}
        {quest.user_submission?.unlocked && (
          <div className="bg-purple-500 rounded-full p-2 shadow-lg transition-all duration-200 group">
            <LockKeyholeOpen className="w-6 h-6 text-white" />
            <div className="absolute hidden group-hover:block right-0 mt-2 bg-gray-900 text-gray-200 text-sm py-1 px-3 rounded-md border border-gray-700 whitespace-nowrap">
              Quest unlocked
            </div>
          </div>
        )}
      </div>
      
      <div className="flex justify-between items-start mb-4 pr-2 md:pr-48">
      <div className="flex-1 pt-6 md:pt-0">
  <h2 className="text-2xl font-bold mb-2">
    <Link
      to={`/quest/${generateQuestSlug(quest.prefix, quest.quest_title)}`} 
      className="text-blue-400 hover:text-blue-300 transition-colors duration-300"
    >
      {quest.quest_title}
    </Link>
  </h2>
  
  {/* Replace the existing time info container with this updated version */}
  {quest.active_to && (
    <div className="h-auto md:h-[60px] flex flex-col gap-1 text-sm">
      {isInitialized && timeLeft && (
        <>
          <div className="flex items-center space-x-4">
            <div className="flex items-center flex-shrink-0">
              <Timer className="w-4 h-4 mr-1.5 text-gray-400" />
              <span className="font-medium text-gray-400">
                {timeLeft.isExpired ? "Ended:" : "Ends:"}
              </span>
              <span className="ml-1.5 text-gray-200 font-semibold">
                {formatDate(quest.active_to)}
              </span>
            </div>
            
            {/* Unlock button for expired quests */}
            {timeLeft.isExpired && !quest.user_submission?.unlocked && (
              <button
                onClick={handleUnlockClick}
                disabled={unlocking}
                className="flex items-center gap-2 px-3 py-1.5 bg-purple-700 hover:bg-purple-800 
                          rounded-lg text-white text-sm font-medium transition-all duration-300 
                          shadow-md hover:shadow-lg transform hover:scale-105 
                          disabled:opacity-50 disabled:transform-none disabled:shadow-none"
              >
                <LockKeyholeOpen className="w-4 h-4" />
                <span className="flex items-center">
                  Unlock (
                  <span className="flex items-center">
                    <span>{UNLOCK_COST}</span>
                    <img 
                      src="/speck_small.png"
                      alt="SPECKS" 
                      className="w-4 h-4 mx-0.5 brightness-100 opacity-100"
                    />
                  </span>
                  )
                </span>
              </button>
            )}
          </div>
          
          {!timeLeft.isExpired && formatCountdown() && (
            <div className="flex items-center mt-0.5">
              <div className="flex items-center bg-gray-900 rounded px-2 py-1">
                <span className="font-mono font-bold text-amber-500">
                  {formatCountdown()}
                </span>
                <span className="ml-2 text-gray-400 text-xs uppercase tracking-wide">
                  remaining
                </span>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )}
</div>
      </div>

      <p className="text-gray-300 mb-4 md:mb-6">{quest.quest_text}</p>

      <div className="grid grid-cols-2 md:grid-cols-4 gap-3 md:gap-8 p-3 md:p-4 bg-gray-800 bg-opacity-50 rounded-lg">
        <div className="flex items-center justify-center space-x-2">
          <img 
            src="/speck_small.png"
            alt="Reward" 
            className="w-6 md:w-8 h-6 md:h-8 brightness-100 opacity-100"
          />
          <span className="text-sm md:text-base text-gray-300">{quest.base_reward} SPECKS</span>
        </div>
        <div className="flex items-center justify-center space-x-2">
          <Users size={18} className="text-gray-400" />
          <span className="text-sm md:text-base text-gray-300">{quest.completions} completed</span>
        </div>
        <div className="flex items-center justify-center space-x-2 cursor-pointer" onClick={handleLikeClick}>
          <Heart 
            size={18} 
            className={`${quest.is_liked ? 'text-red-500 fill-red-500' : 'text-gray-400'} 
              transition-all duration-300 ${isAuthenticated ? 'cursor-pointer hover:scale-110' : 'cursor-not-allowed'}`}
          />
          <span className="text-sm md:text-base text-gray-300">{quest.likes_count}</span>
        </div>
        <div className="flex items-center justify-center space-x-2">
          <Eye size={18} className="text-gray-400" />
          <span className="text-sm md:text-base text-gray-300">{quest.views_count}</span>
        </div>
      </div>

      <UnlockModal
        isOpen={showUnlockModal}
        onClose={() => setShowUnlockModal(false)}
        onConfirm={handleUnlockConfirm}
        specksBalance={userSpecksBalance}
        unlocking={unlocking}
      />
      
      {showToast && (
        <Toast 
          message={toastMessage} 
          type={toastType}
          onClose={() => setShowToast(false)} 
        />
      )}
    </div>
  );
};

QuestInList.propTypes = {
  quest: PropTypes.shape({
    id: PropTypes.number.isRequired,
    prefix: PropTypes.string.isRequired,
    quest_title: PropTypes.string.isRequired,
    quest_text: PropTypes.string.isRequired,
    quest_type: PropTypes.string.isRequired,
    is_active: PropTypes.bool.isRequired,
    base_reward: PropTypes.number.isRequired,
    completions: PropTypes.number.isRequired,
    likes_count: PropTypes.number,
    views_count: PropTypes.number,
    is_liked: PropTypes.bool,
    user_submission: PropTypes.shape({
      unlocked: PropTypes.bool,
      unlocked_at: PropTypes.string,
      is_submitted: PropTypes.bool,
      text: PropTypes.string,
      feed_privacy: PropTypes.string,
    }),
    active_since: PropTypes.string,
    active_to: PropTypes.string,
  }).isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  onLikeClick: PropTypes.func.isRequired,
};

export default QuestInList;