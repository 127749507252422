import React from 'react';
import { Shield } from 'lucide-react';
import TripleToggle from '../ui/TripleToggle';

const FeedVisibility = ({ feedPrivacy, privacyOptions, onChange }) => {
  return (
    <div className="bg-gray-800/30 backdrop-blur-sm rounded-xl p-8 border border-gray-700/50">
      <div className="flex items-start gap-3 mb-6">
        <Shield className="w-5 h-5 text-blue-400 mt-1" />
        <div>
          <h2 className="text-xl font-semibold">Feed Visibility</h2>
          <p className="text-gray-300 text-sm mt-1">Control how your quest responses appear in the global feed</p>
        </div>
      </div>

      <div className="flex justify-center mb-6">
        <TripleToggle
          value={feedPrivacy}
          options={privacyOptions}
          onChange={onChange}
        />
      </div>

      <div className="bg-gray-800/50 rounded-lg p-6">
        <div className="grid grid-cols-3 gap-6">
          <div className="space-y-3">
            <h3 className="font-semibold text-blue-400">Public</h3>
            <p className="text-gray-300 text-sm leading-relaxed">
              Your responses appear with your username in the feed. Other explorers will know that you gave those responses.
            </p>
          </div>
          <div className="space-y-3">
            <h3 className="font-semibold text-blue-400">Anonymous</h3>
            <p className="text-gray-300 text-sm leading-relaxed">
              Your responses appear in the feed without your username. Share your thoughts while maintaining privacy.
            </p>
          </div>
          <div className="space-y-3">
            <h3 className="font-semibold text-blue-400">Private</h3>
            <p className="text-gray-300 text-sm leading-relaxed">
              None of your responses will appear in the global feed. Perfect for those who prefer to explore quietly.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedVisibility;