import React from 'react';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { toast } from 'react-toastify';
import { submitQuest } from '../components/quests/QuestsActions';
import { DEFAULT_FEED_PRIVACY } from '../components/quests/QuestDetail';
import axios from 'axios';
import { setToken, setCurrentUser, unsetCurrentUser } from '../components/login/LoginActions';

export const handlePostAuthActions = async (dispatch, navigate) => {
  const guestAnswer = localStorage.getItem('guestAnswer');
  const guestQuestId = localStorage.getItem('guestQuestId');
  const guestQuestSlug = localStorage.getItem('guestQuestSlug');
  const guestPrivacy = localStorage.getItem('guestPrivacy') || DEFAULT_FEED_PRIVACY;

  if (guestAnswer && guestQuestId) {
    try {
      await dispatch(submitQuest(guestQuestId, guestAnswer, guestPrivacy));
      // Clear localStorage
      ['guestAnswer', 'guestQuestId', 'guestQuestSlug', 'guestPrivacy'].forEach(key => 
        localStorage.removeItem(key)
      );
      
      if (guestQuestSlug) {
        navigate(`/quest/${guestQuestSlug}`);
        return;
      }
    } catch (error) {
      console.error('Failed to submit guest quest:', error);
      toast.error("Failed to submit your answer. Please try again from the quest page.");
    }
  }
  navigate('/');
};

export const handleTwitterAuth = async (credentials, dispatch, navigate, isConnection = false) => {
  try {
      const oauth_token_secret = sessionStorage.getItem('oauth_token_secret');
      
      if (isConnection) {
          const payload = {
              provider: 'twitter',
              oauth_token: credentials.oauth_token,
              oauth_verifier: credentials.oauth_verifier,
              oauth_token_secret: oauth_token_secret,
              access_token: 'twitter' // This is needed because of our validation
          };

          const res = await axios.post('/api/v1/connect-social-account/', payload);
          toast.success('Twitter account connected successfully');
          // Stay on settings page
          navigate('/settings');
      } else {
          // Auth flow remains the same
          const endpoint = '/auth/twitter/callback';
          const payload = {
              ...credentials,
              oauth_token_secret
          };

          const res = await axios.post(endpoint, payload);
          const { token, user } = res.data;
          localStorage.setItem('token', token);
          dispatch(setToken(token));
          dispatch(setCurrentUser(user));
          navigate('/');
      }

      // Clean up session storage
      sessionStorage.removeItem('oauth_token_secret');
      sessionStorage.removeItem('isConnection');

      return true;
  } catch (error) {
      console.error('Twitter auth error:', error);
      const errorMsg = error.response?.data?.error || 'Authentication failed';
      toast.error(errorMsg);
      throw error;
  }
};


export const initiateTwitterAuth = async (isConnection = false) => {
  try {
      const response = await axios.get('/auth/twitter/url');
      const { auth_url, oauth_token, oauth_token_secret } = response.data;

      // Store the oauth token secret and connection state
      sessionStorage.setItem('oauth_token_secret', oauth_token_secret);
      sessionStorage.setItem('isConnection', isConnection.toString());

      // Redirect to Twitter auth
      window.location.href = auth_url;
  } catch (error) {
      const errorMsg = error.response?.data?.error || 'Failed to initiate Twitter authentication';
      toast.error(errorMsg);
      throw error;
  }
};

export const handleGoogleAuth = async (response, dispatch, navigate, isConnection = false) => {
  try {
      if (!response?.credential) {
          throw new Error('No credential received from Google');
      }

      const endpoint = isConnection ? '/api/v1/connect-social-account/' : '/auth/social/';
      const payload = {
          provider: 'google-oauth2',
          access_token: response.credential,
      };

      const res = await axios.post(endpoint, payload);

      if (isConnection) {
          toast.success('Google account connected successfully');
          // Force a page refresh to show the updated state
          window.location.reload();
          return true;
      } else {
          // Handle login flow
          const { token, user } = res.data;
          localStorage.setItem('token', token);
          dispatch(setToken(token));
          dispatch(setCurrentUser(user));
          navigate('/');
      }
      return true;
  } catch (error) {
      console.error('Google auth error:', error);
      const errorMsg = error.response?.data?.error || error.message || 'Authentication failed';
      toast.error(errorMsg);
      return false;
  }
};

// Shared component for Google auth button
export const GoogleAuthButton = ({ onSuccess }) => (
  <div className="w-full flex justify-center mb-4">
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <GoogleLogin
        onSuccess={onSuccess}
        onError={() => toast.error('Google Authentication Failed')}
        theme="filled_black"
        shape="pill"
        width="280px"
      />
    </GoogleOAuthProvider>
  </div>
);