export const GET_QUESTS = "GET_QUESTS";
export const SUBMIT_QUEST = "SUBMIT_QUEST";
export const DELETE_QUEST_SUBMISSION = "DELETE_QUEST_SUBMISSION";
export const UPDATE_QUEST_SUBMISSION = "UPDATE_QUEST_SUBMISSION";
export const OPEN_QUEST = "OPEN_QUEST";
export const FETCH_USER_RESPONSE = "FETCH_USER_RESPONSE";
export const FETCH_QUESTS_START = 'FETCH_QUESTS_START';
export const FETCH_QUESTS_SUCCESS = 'FETCH_QUESTS_SUCCESS';
export const FETCH_QUESTS_FAILURE = 'FETCH_QUESTS_FAILURE';
export const SUBMIT_GUEST_QUEST = "SUBMIT_GUEST_QUEST";
export const FETCH_SINGLE_QUEST_SUCCESS = "FETCH_SINGLE_QUEST_SUCCESS";
export const LIKE_QUEST = "LIKE_QUEST";
export const UNLIKE_QUEST = "UNLIKE_QUEST";
export const ADD_QUEST_VIEW = "ADD_QUEST_VIEW";
export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';
export const UNLOCK_QUEST = "UNLOCK_QUEST";
export const CLEAR_QUESTS_STATE = "CLEAR_QUESTS_STATE";
export const RESET_FINISHED_QUESTS = "RESET_FINISHED_QUESTS";