// SettingsPage.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Toast from '../ui/Toast';
import ProfilePrivacy from './ProfilePrivacy';
import FeedVisibility from './FeedVisibility';
import SocialConnections from './SocialConnections';

const SettingsPage = () => {
  const [privateProfile, setPrivateProfile] = useState(false);
  const privacyOptions = {
    'PUBLIC': 'Public',
    'ANONYMOUS': 'Anonymous',
    'PRIVATE': 'Private'
  };
  const [feedPrivacy, setFeedPrivacy] = useState(privacyOptions.ANONYMOUS);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get('/api/v1/profile/');
        setPrivateProfile(response.data.private_profile);
        setFeedPrivacy(response.data.feed_privacy);
      } catch (error) {
        setToastMessage('Failed to load settings');
        setShowToast(true);
      }
    };
    fetchSettings();
  }, []);

  const handleProfilePrivacyToggle = async () => {
    try {
      const newState = !privateProfile;
      await axios.patch('/api/v1/profile/', {
        private_profile: newState
      });
      
      setPrivateProfile(newState);
      const message = newState 
        ? "Your profile is now private" 
        : "Your profile is now public";
      setToastMessage(message);
      setShowToast(true);
    } catch (error) {
      setToastMessage('Failed to update privacy settings');
      setShowToast(true);
    }
  };

  const handleFeedPrivacyChange = async (newPrivacy) => {
    try {
      await axios.patch('/api/v1/profile/', {
        feed_privacy: newPrivacy
      });
      
      setFeedPrivacy(newPrivacy);
      const messages = {
        [privacyOptions.PUBLIC]: "Your submissions will be public in the feed",
        [privacyOptions.ANONYMOUS]: "Your submissions will be anonymous in the feed",
        [privacyOptions.PRIVATE]: "Your submissions will not appear in the feed"
      };
      setToastMessage(messages[newPrivacy]);
      setShowToast(true);
    } catch (error) {
      setToastMessage('Failed to update feed privacy settings');
      setShowToast(true);
    }
  };

  return (
    <div className="min-h-screen text-white font-sans">
      <div className="max-w-4xl mx-auto p-6">        
        <div className="space-y-8">
          <ProfilePrivacy 
            privateProfile={privateProfile} 
            onToggle={handleProfilePrivacyToggle} 
          />
          
          <FeedVisibility 
            feedPrivacy={feedPrivacy}
            privacyOptions={privacyOptions}
            onChange={handleFeedPrivacyChange}
          />

          <SocialConnections />
        </div>
      </div>
      
      {showToast && (
        <Toast 
          message={toastMessage} 
          type="success" 
          onClose={() => setShowToast(false)} 
        />
      )}
    </div>
  );
};

export default SettingsPage;