import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { 
  User, Trophy, BadgeHelp, Calendar,  
  Target, BarChart, Rocket, Star,
  ChevronLeft, ChevronRight, Lock,
  Edit, Check
} from 'lucide-react';

const WAIT_BEFORE_SHOW_LOADING_VIEW = 500; // milliseconds

const UserProfile = () => {
  const { username: urlUsername } = useParams();
  const { user, isAuthenticated } = useSelector(state => state.auth);
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showLoading, setShowLoading] = useState(false);
  const [error, setError] = useState(null);
  const [sortBy, setSortBy] = useState('submission_datetime');
  const [page, setPage] = useState(1);
  const [editingUsername, setEditingUsername] = useState(false);
  const [newUsername, setNewUsername] = useState(user.username);

  const isOwnProfile = !urlUsername || (user && urlUsername === user.username);

  const handleUsernameChange = async () => {
    try {
      const response = await axios.put('/api/v1/profile/', { username: newUsername });
      setProfile({ ...profile, username: newUsername });
      setEditingUsername(false);
      toast.success('Username updated successfully');
    } catch (error) {
      if (error.response.status === 400) {
        toast.error('Username already exists');
      } else {
        toast.error('Failed to update username');
      }
    }
  };

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        setLoading(true);
        // Create a timer to show loading state after 300ms
        const loadingTimer = setTimeout(() => {
          setShowLoading(true);
        }, WAIT_BEFORE_SHOW_LOADING_VIEW);
        const endpoint = isOwnProfile ? '/api/v1/profile/' : `/api/v1/u/${urlUsername}/`;
        const response = await axios.get(endpoint, {
          params: {
            order_by: sortBy,
            page: page,
            per_page: 5
          }
        });
        setProfile(response.data);
        setNewUsername(response.data.username);
        // Clear the timer and reset states
        clearTimeout(loadingTimer);
        setShowLoading(false);
        setLoading(false);
      } catch (err) {
        console.error("Catched error during fetching profile");
        console.error(err);
        setError(err.response?.status === 404
          ? 'User not found' 
          : err.response?.status === 403
            ? 'This profile is private'
            : 'Failed to load profile'
        );
        setLoading(false);
        setShowLoading(false);
      }
    };

    if (isAuthenticated || urlUsername) {
      fetchProfile();
    }
  }, [isAuthenticated, urlUsername, sortBy, page, isOwnProfile]);

  if (loading && showLoading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="text-white">Loading profile...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="bg-gray-800 bg-opacity-80 rounded-xl p-8 border border-gray-700 text-center">
          <Lock className="mx-auto mb-4 text-gray-400" size={48} />
          <div className="text-xl text-red-400 mb-2">Access Restricted</div>
          <div className="text-gray-400">{error}</div>
        </div>
      </div>
    );
  }

  if (!profile) {
    return null;
  }

const renderPrivateProfile = (profileUsername) => (
  <div className="max-w-6xl mx-auto p-6 space-y-8">
    {/* Profile Header - same style as public profile */}
    <div className="bg-gray-800 bg-opacity-80 rounded-xl p-6 border border-gray-700">
      <div className="flex items-center space-x-6">
        <div className="relative">
          <div className="w-24 h-24 rounded-full bg-gradient-to-r from-blue-500 to-purple-600 flex items-center justify-center">
            <User size={48} className="text-white" />
          </div>
        </div>
        <div className="flex-1">
          <h1 className="text-3xl font-bold text-white mb-2">
            {profileUsername}
          </h1>
        </div>
      </div>
    </div>

    {/* Private Message Card */}
    <div className="bg-gray-800 bg-opacity-80 rounded-xl p-8 border border-gray-700 text-center">
      <Lock className="w-16 h-16 text-gray-500 mx-auto mb-4" />
      <p className="text-gray-400 text-lg">This user's profile is private</p>
    </div>
  </div>
);

  if (profile.is_private && !isOwnProfile) {
    return renderPrivateProfile(profile.username);
  }

  const averageReward = profile.average_reward || 0;
  const averageAddedVocabulary = profile.average_words_added || 0;

  const handleSortChange = (newSortBy) => {
    setSortBy(newSortBy);
    setPage(1);
  };

  const handlePageChange = (direction) => {
    if (direction === 'prev' && page > 1) {
      setPage(page - 1);
    } else if (direction === 'next') {
      setPage(page + 1);
    }
  };

  const StatCard = ({ icon: Icon, label, value }) => (
    <div className="bg-gray-800 bg-opacity-60 rounded-lg p-4 flex items-center space-x-3 border border-gray-700 hover:border-blue-500 transition-colors duration-300">
      <div className="p-2 bg-blue-500 bg-opacity-20 rounded-lg">
        <Icon className="text-indigo-500" size={20} />
      </div>
      <div>
        <p className="text-sm text-gray-400">{label}</p>
        <p className="text-lg font-semibold text-white">{value}</p>
      </div>
    </div>
  );

  const SortButton = ({ label, active, onClick }) => (
    <button
      onClick={onClick}
      className={`px-4 py-2 rounded-lg transition-all duration-300 ${
        active 
          ? 'bg-indigo-600 text-white text-md' 
          : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
      }`}
    >
      {label}
    </button>
  );

  return (
    <div className="max-w-6xl mx-auto p-6 space-y-8">
    {/* Profile Header */}
    <div className="bg-gray-800 bg-opacity-80 rounded-xl p-6 border border-gray-700">
      <div className="block lg:flex lg:items-center lg:justify-between">
        <div className="flex items-center space-x-6">
          <div className="relative">
            <div className="w-24 h-24 rounded-full bg-gradient-to-r from-blue-500 to-purple-600 flex items-center justify-center">
              <User size={48} className="text-white" />
            </div>
            <div className="absolute -bottom-2 -right-2 bg-green-500 p-1 rounded-full">
              <div className="w-4 h-4 bg-green-500 rounded-full border-2 border-gray-800" />
            </div>
          </div>
          <div>
            {/* Insert the new code here */}
            <div>
            <div className="flex items-center space-x-2">
              <h1 className="text-3xl font-bold text-white mb-2">
                {editingUsername ? (
                  <input
                    type="text"
                    value={newUsername}
                    onChange={e => setNewUsername(e.target.value)}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        handleUsernameChange();
                      }
                    }}
                    className="bg-transparent border-b border-white focus:outline-none w-full sm:w-auto"
                  />
                ) : (
                  <span className="truncate">{profile.username}</span>
                )}
              </h1>
              {isOwnProfile && (
                <button
                  onClick={() => setEditingUsername(!editingUsername)}
                  className="text-blue-500 hover:text-blue-400 ml-2"
                >
                  {editingUsername ? (
                    <Check size={20} onClick={handleUsernameChange} />
                  ) : (
                    <Edit size={20} />
                  )}
                </button>
              )}
            </div>
          </div>
              {/* End of the new code */}
            <div className="flex items-center space-x-4 text-gray-400">
              <span className="flex items-center">
                <Trophy size={16} className="mr-1 text-yellow-400" />
                Abyss Friends League
              </span>
              <span className="flex items-center">
                <Calendar size={16} className="mr-1" />
                Joined {new Date(profile.date_joined).toLocaleDateString()}
              </span>
            </div>
          </div>
        </div>
        <div className="flex justify-center w-full lg:w-auto mt-6 lg:mt-0">
          <div className="bg-gray-900 rounded-lg p-4 border border-gray-700 w-[200px] lg:w-auto">
            <div className="flex items-center justify-center space-x-2">
              <img 
                src="/speck_icon.png"
                alt="Specks" 
                className="w-6 h-6"
              />
              <span className="text-2xl font-bold text-indigo-500">{profile.specks_balance}</span>
            </div>
            <p className="text-sm text-gray-400 mt-1 text-center">SPECKS Balance</p>
          </div>
        </div>
      </div>
    </div>

      {/* Stats Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        <StatCard 
          icon={Rocket} 
          label="Daily Streak" 
          value={`${profile.daily_streak} days`} 
        />
        <StatCard 
          icon={Target} 
          label="Quests Completed" 
          value={profile.total_submitted_quests} 
        />
        <StatCard 
          icon={Star} 
          label="Average Reward" 
          value={`${averageReward} SPECKS`} 
        />
        <StatCard 
          icon={Trophy} 
          label="Average Vocabulary Added" 
          value={`${averageAddedVocabulary} words`} 
        />
      </div>

      {/* Achievements Section */}
      <div className="bg-gray-800 bg-opacity-80 rounded-xl p-6 border border-gray-700">
        <h2 className="text-xl font-semibold text-white mb-4 flex items-center">
          <Trophy className="mr-2 text-yellow-400" />
          Recent Achievements
        </h2>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          {[...Array(4)].map((_, i) => (
            <div key={i} className="bg-gray-700 bg-opacity-50 rounded-lg p-4 text-center filter grayscale hover:grayscale-0 hover:shadow-[0_0_30px_rgba(255,191,50,0.8)] backdrop-blur-sm transition-all duration-300">
              <Star className="mx-auto mb-2 text-yellow-400" size={24} />
              <p className="text-sm text-gray-300">Achievement Locked</p>
            </div>
          ))}
        </div>
      </div>

      {/* Submitted Quests Section */}
      <div className="bg-gray-800 bg-opacity-80 rounded-xl p-6 border border-gray-700">
  <div className="flex flex-col space-y-4 lg:flex-row lg:justify-between lg:items-center lg:space-y-0 mb-6">
    <h2 className="text-xl font-semibold text-white flex items-center">
      <BarChart className="mr-2 text-indigo-500" />
      Submission History
    </h2>
    <div className="flex flex-wrap gap-2">
      <SortButton 
        label="Specks" 
        active={sortBy === 'specks'} 
        onClick={() => handleSortChange('specks')} 
      />
      <SortButton 
        label="Submission Date" 
        active={sortBy === 'submission_datetime'} 
        onClick={() => handleSortChange('submission_datetime')} 
      />
      <SortButton 
        label="Quest Start Date" 
        active={sortBy === 'quest_active_since'} 
        onClick={() => handleSortChange('quest_active_since')} 
      />
    </div>
  </div>

        <div className="overflow-x-auto">
        <table className="w-full mb-4">
          <thead>
            <tr>
              <th className="text-left px-4 py-2">Quest Name</th>
              <th className="text-left px-4 py-2">Specks Earned</th>
              <th className="text-left px-4 py-2">Submission Date</th>
              <th className="text-left px-4 py-2">Quest Start Date</th>
              <th className="text-left px-4 py-2">Quest End Date</th>
            </tr>
          </thead>
          <tbody>
            {profile.submitted_quests.map((quest, index) => (
              <tr key={index} className="border-t border-gray-700">
                <td className="px-4 py-2">{quest.quest_title}</td>
                <td className="px-4 py-2">
                  <div className="flex items-center">
                    {(quest.is_active || (quest.is_submitted && quest.unlocked && (quest.value_reward === 0))) ? (
                      <>
                        <span>{quest.base_reward}</span>
                        <span className="mx-1">+</span>
                        <BadgeHelp size={16} className="text-yellow-400" />
                      </>
                    ) : (
                      quest.base_reward + (quest.value_reward || 0)
                    )}
                  </div>
                </td>
                <td className="px-4 py-2">{new Date(quest.submission_datetime).toLocaleDateString()}</td>
                <td className="px-4 py-2">{new Date(quest.quest_active_since).toLocaleDateString()}</td>
                <td className="px-4 py-2">
                  {quest.quest_active_to ? 
                    new Date(quest.quest_active_to).toLocaleDateString() : 
                    'Active'
                  }
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>

        <div className="flex justify-between items-center mt-4">
          <button
            onClick={() => handlePageChange('prev')}
            disabled={page === 1}
            className="flex items-center px-4 py-2 rounded-lg bg-gray-700 text-gray-300 hover:bg-gray-600 transition-colors duration-300 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <ChevronLeft size={16} className="mr-1" />
            Previous
          </button>
          <button
            onClick={() => handlePageChange('next')}
            disabled={!profile.submitted_quests.length}
            className="flex items-center px-4 py-2 rounded-lg bg-gray-700 text-gray-300 hover:bg-gray-600 transition-colors duration-300 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Next
            <ChevronRight size={16} className="ml-1" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;