import { SET_TOKEN, SET_CURRENT_USER, UNSET_CURRENT_USER } from "./LoginTypes";
import {
  FETCH_PROFILE_SUCCESS
} from '../profile/ProfileTypes';

const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: null,
  user: null
};

export const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOKEN:
      return {
        ...state,
        isAuthenticated: true,
        token: action.payload
      };
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload
      };
    case UNSET_CURRENT_USER:
      return {
        ...initialState,
        isAuthenticated: false,
        user: null,
        token: null,
        persistedData: null  // Clear any persisted data
      };
    case FETCH_PROFILE_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload
        }
      };
    default:
      return state;
  }
};